.PageList {

    display: flex;
    flex-direction: column;

    > header {
        padding: var(--spacing-small) 0 var(--spacing-medium) 0;
        text-align: center;
        color: var(--colour-text-accent-secondary);
        > * {
            padding-left: var(--spacing-medium);
            padding-right: var(--spacing-medium);
            text-align: center;
        }
        > h1 {
            margin-bottom: 0;
        }
        > p {
            font-size: 1.5em;
            line-height: 1.2em;
            padding: 0;
        }
        > :first-child {
            margin-top: 0;
            padding-top: 0;
        }
        > :last-child {
            margin-bottom: 0;
            padding-bottom: 0;
        }
    }

    > ul {
        margin: var(--spacing-small) 0;
        display: grid;
        grid-template-columns: repeat(auto-fit,minmax(var(--card-min-width),1fr));
        gap: var(--spacing-small);
        list-style-type: none;
        padding: 0;
        > li {
            border-bottom: 1px solid var(--colour-text-accent-primary);
            picture { margin-bottom: var(--spacing-small); }
            a:first-of-type { font-size: var(--font-size-header) }
            header { display: none; }
            &:first-child:last-child { max-width: var(--card-max-width); }
        }
    }
    > header:not(:empty) + ul {
        margin-top: 0;
    }

}