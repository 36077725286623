.Issues {

    ul {
        display: grid;
        grid-template-columns: repeat(auto-fit,minmax(var(--card-min-width),1fr));
        gap: var(--spacing-medium);
        margin: 0;
        padding: 0;
    }

    li { list-style-type: none; }

}